import { useQuery } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { keys } from './frameCompany.keys';
import { useEffect, useState } from 'react';
import { useDebounce } from '../../../features/redListedCompanies/helpers/debounceSearch';

export function useFindAllUsersFrameCompany(companyId?: string) {
    const getApi = useApi();



    const getFrameCompaniesUsers = async () => {
        const api = await getApi();
        const res = await api.frameCompanyFindResponsibleUsers();
        return res;
    };

    const query = useQuery({
        queryFn: getFrameCompaniesUsers,
    });

    return {
        ...query,
        data: query.data?.data,

    };
}
