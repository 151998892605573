import { H3 } from '@ansvar-sakerhet/ui/src/typography/H3';
import React, { useState } from 'react';
import { S } from './News.styles';
import { NewsLetter } from '../../components/NewsLetter';
import { OtherNews } from '../../components/OtherNews';
import { useGetAllNews } from '../../../../traits/api/news/useGetAllNews';
import { Loading } from '../../../../components/Loading';
import { useParams } from 'react-router-dom';
import { useNewsLetter } from '../../hooks/useNewsletter';
import { useOtherNewsletters } from '../../hooks/useOtherNewsletters';
import { Statistics } from '../../components/Statistics';
import { BackgroundControlsTable } from '../../../frameCompanies/screens/SingleCompany/components/BackgroundControlsTable';
import { ControlStats } from '../../components/Statistics/components/ControlStats/ControlStats';
import { useOverviewStatistics } from '../../../../traits/api/overviewStatistics/useOverviewStatistics';
import { BackgroundControlsStats } from '../../components/Statistics/components/BackgroundControlsStats/BackgroundControlsStats';

export const News: React.FC = () => {
  const [take, setTake] = useState(4);
  const { newsId } = useParams();
  const { data: newsLetters, isLoading, count } = useGetAllNews(take);
  const { news, isLoading: newsLetterLoading } = useNewsLetter(
    newsId,
    newsLetters
  );
  const { data: stats, isLoading: loadingStats } = useOverviewStatistics();

  const otherNews = useOtherNewsletters(news, newsLetters);
  const onShowMore = () => {
    setTake(take + 4);
  };

  if (
    isLoading ||
    newsLetterLoading ||
    !newsLetters ||
    loadingStats ||
    !stats
  ) {
    return (
      <S.MainContainer>
        <S.MainTitleContainer>
          <H3>Nyheter</H3>
        </S.MainTitleContainer>
        <S.Margin $bottom="m" />
        <Loading />
      </S.MainContainer>
    );
  }

  return (
    <S.MainContainer>
      <S.MainTitleContainer>
        <H3>Dashboard - Avvikelsestatistik</H3>
      </S.MainTitleContainer>
      <S.StatsContainer $flexWrap="wrap" $justify={'between'} $gap="10px">
        <BackgroundControlsStats stats={stats} />
        <ControlStats stats={stats} name={stats.clientName} />
      </S.StatsContainer>

      <S.Margin $bottom="l" />
      <S.MainTitleContainer>
        <H3>Nyheter</H3>
      </S.MainTitleContainer>

      <S.Flex $flexDir="row" $width="100%">
        <S.NewsGridContainer>
          <NewsLetter news={news} />
          <OtherNews
            canShowMore={take < count}
            onShowMore={onShowMore}
            news={otherNews}
          />
        </S.NewsGridContainer>
      </S.Flex>
    </S.MainContainer>
  );
};
