import { UIElements } from '@ansvar-sakerhet/ui';
import styled from 'styled-components';

const FrameCompanies = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const BackButton = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const FrameCompany = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  border-radius: 4px;
  width: 100%;
`;

const FrameCompanyTitle = styled.h4`
  font-size: 1.25rem;
  font-weight: 500;
`;

const FrameCompanyDescription = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;

const Form = styled.form`
  max-width: 400px;
`;

export const S = {
  BackButton,
  Form,
  FrameCompanies,
  FrameCompany,
  FrameCompanyTitle,
  FrameCompanyDescription,
  ...UIElements,
};
